// 原生工具类
import md5 from '../js/md5'
import VueCookies from 'vue-cookies'
import '../js/lyQiyukf'
import {
  encrypt,
  decrypt
} from './encryption'
class ComTools {
  constructor() {

  }
  /**
   * @param {目标对象} target 
   * @param {已有对象} obj 
   */
  assignObj(target, obj) {
    let newObj = Object.assign(target, obj);
    // console.log(newObj);
    return newObj;
  }

  /**
   * 防抖函数
   * @param {需要的防抖函数} fn 
   * @param {时间} delay 
   */
  setDebounce(fn, delay) {
    let _delay = delay || 1000;
    let timer;
    return function () {
      let th = this;
      let args = arguments;
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(function () {
        timer = null;
        fn.apply(th, args);
      }, _delay);
    };
  }

  //在线客服
  onlineService() {
    ysf('config', {
      uid: VueCookies.get('uid'),
      name: VueCookies.get('uname'),
      mobile: VueCookies.get('mobile') || appData.mobile || "未知",
      data: '',
      success: function () {
        ysf('open');
        console.log("success");
      },
      error: function () {
        console.log("error");
      }
    });
  }



  /**
   * 节流函数
   * @param {需要的节流函数} fn 
   * @param {时间} interval 
   */
  setThrottle(fn, interval) {
    let last;
    let timer;
    let _interval = interval || 1000;
    return function () {
      let th = this;
      let args = arguments;
      let now = +new Date();
      if (last && now - last < _interval) {
        clearTimeout(timer);
        timer = setTimeout(function () {
          last = now;
          fn.apply(th, args);
        }, _interval);
      } else {
        last = now;
        fn.apply(th, args);
      }
    }
  }

  /**
   * 获取地址栏参数集合
   */
  getRequest() {
    // if (window.location.pathname != '/home') return;
    var url = window.location.search;
    var jsonList = {};
    if (url.indexOf("?") > -1) {
      var str = url.slice(url.indexOf("?") + 1);
      var strs = str.split("&");
      strs.forEach((item, i) => {
        jsonList[strs[i].split("=")[0]] = strs[i].split("=")[1]; //如果出现乱码的话，可以用decodeURI()进行解码
      })
    }


    return jsonList;
  }

  /**
   * 获取时间戳
   */
  getTm() {
    let tmp = Date.parse(new Date())
    if (tmp) {
      tmp = tmp.toString().substr(0, 10);;
    } else {
      tmp = Math.round(new Date().getTime() / 1000)
    }
    return tmp;
  }

  //加密
  jiam(data) {
    Object.keys(data).forEach((key) => {
      // foo
      data[key] = encrypt(data[key])
      console.log(data[key])
    })
    console.log(data);
    return data
  }


  /**
   * 获取md5
   * @param {*} target
   */
  getMd5(target) {
    return md5(target);
  }
  /**
   * 加密处理
   * @param {*} queryObj 
   * @param {*} noMd5 
   * @param {*} elseQuery 
   */
  querySort(queryObj, noMd5, elseQuery) {
    const keyArr = Object.keys(queryObj);
    let str = '';
    // 兼容低端安卓机
    if (!Array.prototype.findIndex) {
      Array.prototype.findIndex = function (predicate) {
        if (this == null) {
          throw new TypeError('Array.prototype.findIndex called on null or undefined');
        }
        if (typeof predicate !== 'function') {
          throw new TypeError('predicate must be a function');
        }
        var list = Object(this);
        var length = list.length >>> 0;
        var thisArg = arguments[1];
        var value;

        for (var i = 0; i < length; i++) {
          value = list[i];
          if (predicate.call(thisArg, value, i, list)) {
            return i;
          }
        }
        return -1;
      };
    }
    keyArr.indexOf('sign') > -1 ? keyArr.splice(keyArr.findIndex(item => item === 'sign'), 1) : ''
    keyArr.sort();
    for (let i = 0; i < keyArr.length; i += 1) {
      if (queryObj[keyArr[i]] !== 0 && queryObj[keyArr[i]]) {
        str = `${str}&${keyArr[i]}=${queryObj[keyArr[i]]}`;
      }
    }
    if (noMd5) {
      return `${str.substr(1)}`;
    } else {
      let arr = ['173', '183']
      if (elseQuery && arr.indexOf(elseQuery.pid.toString()) > -1) {
        // console.log(`${str.substr(1)}${elseQuery.appKey}`,'======sign')
        return this.getMd5(`${str.substr(1)}${elseQuery.appKey}`);
      } else {
        // console.log('sign',str.substr(1))
        return this.getMd5(`${str.substr(1)}&`);
      }
    }
  }

  dataURLtoBlob(dataurl) {
    var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {
        type: mime
    });
}

  /**
   * 是否ios系统
   */
  isIos() {
    let u = navigator.userAgent;
    if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) { //安卓手机
      return false
    } else if (u.indexOf('iPhone') > -1) { //苹果手机
      return true
    } else if (u.indexOf('iPad') > -1) { //iPad
      return false
    } else if (u.indexOf('Windows Phone') > -1) { //winphone手机
      return false
    } else {
      return false
    }
  }

  //深拷贝
  deepClone(target) {
    // 定义一个变量
    let result;
    // 如果当前需要深拷贝的是一个对象的话
    if (typeof target === 'object') {
      // 如果是一个数组的话
      if (Array.isArray(target)) {
        result = []; // 将result赋值为一个数组，并且执行遍历
        for (let i in target) {
          // 递归克隆数组中的每一项
          result.push(this.deepClone(target[i]))
        }
        // 判断如果当前的值是null的话；直接赋值为null
      } else if (target === null) {
        result = null;
        // 判断如果当前的值是一个RegExp对象的话，直接赋值    
      } else if (target.constructor === RegExp) {
        result = target;
      } else {
        // 否则是普通对象，直接for in循环，递归赋值对象的所有值
        result = {};
        for (let i in target) {
          result[i] = this.deepClone(target[i]);
        }
      }
      // 如果不是对象的话，就是基本数据类型，那么直接赋值
    } else {
      result = target;
    }
    // 返回最终结果
    return result;
  }



}
let comTools = new ComTools();
export default comTools

// 防抖
// export function setDebounce(fn, delay) {
//   let _delay = delay || 1500;
//   let timer;
//   return function () {
//     let th = this;
//     let args = arguments;
//     if (timer) {
//       clearTimeout(timer);
//     }
//     timer = setTimeout(function () {
//       timer = null;
//       fn.apply(th, args);
//     }, _delay);
//   };
// }
// // 节流
// export function setThrottle(fn, interval) {
//   let last;
//   let timer;
//   let _interval = interval || 10000000;
//   return function () {
//     let th = this;
//     let args = arguments;
//     let now = +new Date();
//     if (last && now - last < _interval) {
//       clearTimeout(timer);
//       timer = setTimeout(function () {
//         last = now;
//         fn.apply(th, args);
//       }, _interval);
//     } else {
//       last = now;
//       fn.apply(th, args);
//     }
//   }
// }