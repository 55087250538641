<template>
  <div class="title_content">
    {{ titleName }}
    <van-icon
      v-if="goback"
      name="arrow-left"
      @click="$router.go(-1)"
      class="goback"
    />
    <p class="title2" v-if="rightTitle" @click="title2Go">{{ rightTitle }}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["titleName", "goback", "rightTitle", "goPath"],
  methods: {
    title2Go() {
      if (this.goPath) {
        this.$router.push({ path: this.goPath });
      } else {
        if (this.rightTitle == "编辑") {
          this.$emit("edit", "取消");
        } else {
          this.$emit("edit", "编辑");
        }
      }
    },
  },
};
</script>

<style lang="less"  scoped>
.title_content {
  color: #000;
  width: 100vw;
  height: 13.2vw;
  line-height: 13.2vw;
  background: #fff;
  text-align: center;
  font-size: 5vw;
  font-weight: 600;
  position: fixed;
  z-index: 1000;
  .goback {
    position: absolute;
    left: 4vw;
    top: 50%;
    transform: translateY(-50%);
    font-size: 4vw;
    color: #666;
  }
  .title2 {
    position: absolute;
    right: 4vw;
    top: 50%;
    font-size: 3.5vw;
    transform: translateY(-50%);
  }
}
</style>