import service from './service'
const qs = require('qs');
let protocol = 'https';
let name = 'dingdangyou';
let suffix = 'cn';
if (process.env.NODE_ENV === 'production') {
  // 生产环境
  protocol = 'https:';
  name = 'dingdangyou';
  suffix = 'cn';
} else {
  // 开发环境
  // protocol = 'http:';
  // name = 'gamehl';
  // suffix = 'my';
  protocol = 'https:';
  name = 'dingdangyou';
  suffix = 'cn';
}

//抽奖活动规则
const rule = `${protocol}//sdkapi.${name}.${suffix}/api/v1/draw/rule`
const init = `${protocol}//sdkapi.${name}.${suffix}/api/v1/draw/init`
const login = `${protocol}//sdkapi.${name}.${suffix}/api/v1/draw/login`
const choiceAccountLogin = `${protocol}//sdkapi.${name}.${suffix}/api/v1/draw/choiceAccountLogin`
const start = `${protocol}//sdkapi.${name}.${suffix}/api/v1/draw/recharge_index`
const prizeRecord = `${protocol}//sdkapi.${name}.${suffix}/api/v1/draw/prizeRecord`
const sdkDrawTimes = `${protocol}//sdkapi.${name}.${suffix}/api/v1/draw/get_user_info`
const choosePrizeAccount = `${protocol}//sdkapi.${name}.${suffix}/api/v1/draw/get_son_num`


//抽奖活动规则
const Rule = (data) => {
  return service.request({
    url: rule,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data:qs.stringify(data)
  }).then(res=>{
    return res.data
    
  })
}

//初始化
const Init = (data) => {
  return service.request({
    url: init,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data:qs.stringify(data)
  }).then(res=>{
    return res.data
    
  })
}

//用户登录
const Login = (data) => {
  return service.request({
    url: login,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data:qs.stringify(data)
  }).then(res=>{
    return res.data
    
  })
}

//选主号登录
const ChoiceAccountLogin = (data) => {
  return service.request({
    url: choiceAccountLogin,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data:qs.stringify(data)
  }).then(res=>{
    return res.data
    
  })
}

//抽奖
const Start = (data) => {
  return service.request({
    url: start,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data:qs.stringify(data)
  }).then(res=>{
    return res.data
    
  })
}

//抽奖记录
const PrizeRecord = (data) => {
  return service.request({
    url: prizeRecord,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data:qs.stringify(data)
  }).then(res=>{
    return res.data
    
  })
}

//SDK抽奖次数
const SdkDrawTimes = (data) => {
  return service.request({
    url: sdkDrawTimes,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data:qs.stringify(data)
  }).then(res=>{
    return res.data
    
  })
}

//选择奖励游戏
const ChoosePrizeAccount = (data) => {
  return service.request({
    url: choosePrizeAccount,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data:qs.stringify(data)
  }).then(res=>{
    return res.data
    
  })
}

export default{
  Rule,
  Init,
  Login,
  ChoiceAccountLogin,
  Start,
  PrizeRecord,
  SdkDrawTimes,
  ChoosePrizeAccount
}