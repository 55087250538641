import axios from 'axios'

/* 一般都是使用全局配置,在此我是通过创建实例的方法,优点:当接口不在一个服务器上,我们可以通过这种创建实例的方法方便管理和使用 */
// // 第二种写法
const request = (config) => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      // baseURL: 'http://192.168.1.115:3000',
      headers:config.headers,
      method:config.method,
      timeout: 5000,
    })
    instance(config).then(res => {
      resolve(res);
    }).catch(err => {
      reject(err);
    })
  })
}


export default {
    request,
}
