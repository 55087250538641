(function (w, d, n, a, j) {
  w[n] = w[n] || function () {
    (w[n].a = w[n].a || []).push(arguments);
  };
  j = d.createElement('script');
  j.async = true;
  j.src = 'https://qiyukf.com/script/b8930154e57a5dc63ae754118a5b3e93.js';
  d.body.appendChild(j);
})(window, document, 'ysf');

ysf('onready', function () {
  // todo
  console.log('sdk ready');

});

// let wxtimer = null
// wxtimer = setInterval(() => {
//   if (document.getElementById("YSF-BTN-HOLDER")) {
//     // console.log('客服图标',$('layer-7'));
//     // comTool.delHtml(['layer-7'])

//     let e = document.getElementById("YSF-BTN-HOLDER"); 
//     let child = e.lastElementChild;  
//     while (child) { 
//         console.log(9999999);
//         e.removeChild(child); 
//         child = e.lastElementChild; 
//     } 
    
//     clearInterval(wxtimer)
//   }
// }, 1)